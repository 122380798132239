/*


 */

import $ from 'jquery';
import MatchHeight from 'matchheight';
import slick from "slick-carousel";

/*import IntersectionManager from './effects/intersection/IntersectionManager';
import IntersectionObject from './effects/intersection/IntersectionObject';*/


class App {


    constructor() {

        //this.setIntersectionObserver();
        $(()=>{

            this.setHeaderMenu();
            this.initSmoothScroll();

            this.$header = $("#prg-header");
            setInterval(()=>{
                this.handleScroll();
            },200)

            $("#prg-top-mv-slide").slick({
                autoplay: true,
                autoplaySpeed: 4000,
                speed: 2000,
                fade: true,
            });
            $("#prg-top-style-slide").slick({
                autoplay: false,
                slidesToShow: 4,
                speed: 1000,
                arrows: true,
                nextArrow: '<p class="style__arrow"><img src="/assets/img/slide_next.png" alt="" /></p>',
                responsive: [{

                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    }

                }]
            });
            MatchHeight.init();

        })


    }

    setHeaderMenu() {

        this.$menu = $("#prg-header-menu");
        this.$menuBtn = $("#prg-menu-btn");
        this.$closeBtn = $("#prg-menu-close");
        this.sclock = false;
        this.$menuBtn.on("click", ()=>this.menuBtnClickHandler());
        this.$closeBtn.on("click", ()=>this.menuBtnClickHandler());

    }

    menuBtnClickHandler() {


        if (this.$menu.hasClass("is-active")) {

            this.sclock = false;
            this.$menu.removeClass("is-active");
            this.$menu.addClass("is-fadeOut");
            this.$menuBtn.removeClass("is-active");

            //scrollLock.setScrollLock(false);
            /*requestAnimationFrame(() => {
                $(window).scrollTop(tmpScroll);
            })*/
            setTimeout(()=> {
                this.$menu.removeClass("is-fadeOut");
            }, 500)
        } else {
            this.sclock = true;
            //this.tmpScroll = $(window).scrollTop();
            this.$menu.addClass("is-beforeEnter");
            this.$menuBtn.addClass("is-active");
            requestAnimationFrame(()=> {
                this.$menu.removeClass("is-beforeEnter");
                this.$menu.addClass("is-active");
            })
            /*setTimeout(() => {
                scrollLock.setScrollLock(true);
            }, 500)*/
        }
    }

    handleResize(){
        this.sW = $(window).innerWidth();
        this.sH = $(window).innerHeight();
        this.footerPos = $(".footer").offset().top;

        if(this.top) {
            this.top.handleResize(this.sW,this.sH);
        }
    }
    handleScroll(){
        let st = $(window).scrollTop();
        if(st > 1000){
            this.$header.addClass("is-fixed");
            requestAnimationFrame(()=>{
                this.$header.addClass("is-fixed-active");
            })
        } else {
            this.$header.removeClass("is-fixed").removeClass("is-fixed-active");
        }
    }

    /*
       * スムーススクロール
       */
    initSmoothScroll() {
        $('a[href^="#"]').not(".no-scroll").on("click", (_t) => {
            var speed = 600;

            var href = $(_t.currentTarget).attr("href");

            //クリックターゲットにhref要素が見つからない場合、親に遡って探す
            var parent = $(_t.target);
            var n = 0;
            while (href === undefined) {
                parent = parent.parent();
                href = parent.attr("href");
                n++;
                if (n > 10) {
                    break;
                }
            }
            var target = $(href);
            if (href == "#") {
                target = $("body");
            }
            let offset = $(_t.currentTarget).data("offset");
            offset = (!offset) ? 0 : offset
            this.smoothScrollTo(target, speed, offset);
            return false;
        })
    }


    smoothScrollTo(target, speed, offset) {

        if (!offset) {
            offset = 0
        }

        var position = target.offset().top + offset;
        $('body,html').animate({ scrollTop: position }, speed);
    }

    setIntersectionObserver() {

        let _this = this;
        /**
         * observe ef-scroll
         */
        const mutationObserver = new MutationObserver(function (records) {
            records.forEach(function (record) {
                var oldVal = record.oldValue;
                var newVal = record.target.className;
                if (oldVal.indexOf("ef-scroll") > 0 && newVal.indexOf("ef-scroll<0")) {
                    _this[$(record.target).data("func")]();
                }

            })
        })
        var options = {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ["class"]
        }
        $(".prg-hook").each(function () {
            mutationObserver.observe($(this)[0], options);
        })


        //intersection observer
        let opt = {
            selector: '.ef-scroll',
            rootMargin: "-10% 0px"
        };
        let ioManager = new IntersectionManager(opt);
        for (let i = 0; i < $(".ef-scroll").length; i++) {
            (function (i) {
                let $d = $(".ef-scroll").eq(i);

                let io = new IntersectionObject($d, null);
                ioManager.addObject(io);
            })(i)
        }
        ioManager.startIntersection();
    }

}



const _app = new App();
